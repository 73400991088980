<template>
    <div class="darmin card">
        <div
            class="card-header pb-0 d-flex align-items-center justify-content-between"
            data-bs-toggle="collapse"
            :data-bs-target="`#card-${ramId}`"
            :aria-expanded="true"
            :aria-controls="`card-${ramId}`"
        >
            <h5>Observaciones Internas</h5>
            <i class="fas fa-angle-up"></i>
        </div>
        <div class="card-body theme-form">
            <div class="collapse show" :id="`card-${ramId}`">
                <div class="row" v-if="!disabled">
                    <div class="col-lg-6">
                        <div class="table-responsive custom-scrollbar">
                            <table class="table table-bordernone">
                                <tbody>
                                    <tr v-for="(o, i) in obs" :key="i">
                                        <td>
                                            <div class="d-flex justify-content-between">
                                                <div class="media">
                                                    <div class="media-body">
                                                        <h5>{{ o.contenido }}</h5>
                                                        <p class="">
                                                            {{
                                                                o.createdBy.fullName != undefined
                                                                    ? o.createdBy.fullName
                                                                    : ''
                                                            }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="d-flex flex-column align-items-center">
                                                    <span
                                                        class="badge badge-light-theme-light font-theme-light mb-auto"
                                                        >{{ o.createdAt }}</span
                                                    >
                                                    <div
                                                        @click="deleteNewObservacion(i)"
                                                        class="cursor-pointer px-1"
                                                    >
                                                        <i
                                                            class="far fa-trash text-danger cursor-pointer"
                                                            v-if="o._id === undefined"
                                                        ></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-lg-6 mb-2">
                        <div>
                            <label class="form-label" for="exampleFormControlTextarea4"
                                >Añadir Observacion</label
                            >
                            <textarea
                                class="form-control"
                                id="exampleFormControlTextarea4"
                                rows="3"
                                v-model="contenido"
                            ></textarea>
                        </div>
                    </div>
                    <div class="col-12 text-end">
                        <div class="btn btn-primary ms-3 mb-1" @click="addNewObervacion">
                            <i class="far fa-plus"></i> Añadir
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';

export default {
    name: 'CitasObservaciones',
    props: {
        observaciones: Array,
        disabled: Boolean,
    },
    setup(props, { emit }) {
        const store = useStore();

        const contenido = ref('');
        const ramId = ref(Math.floor(Math.random() * 1000));
        const obs = computed(
            () =>
                // eslint-disable-next-line implicit-arrow-linebreak
                props.observaciones.map((o) => ({
                    ...o,
                    localId: uuidv4(),
                    createdAt: DateTime.fromISO(o.createdAt).toRelative(),
                    // eslint-disable-next-line comma-dangle
                }))
            // eslint-disable-next-line function-paren-newline
        );
        const addNewObervacion = () => {
            const observacion = {
                contenido: contenido.value,
                createdBy: store.getters['Auth/getUserObj'],
                createdAt: DateTime.now().toISO(),
            };
            emit('onAddObservacion', { observacion });
            contenido.value = '';
        };

        const deleteNewObservacion = (idx) => {
            console.log('idx', idx);
            emit('onDeleteObservacion', { index: idx });
        };

        return {
            obs,
            ramId,
            contenido,
            addNewObervacion,
            deleteNewObservacion,
        };
    },
};
</script>

<style></style>

<template>
    <WindowLayout :title="title">
        <div class="row">
            <div class="col-sm-12">
                <div class="darmin card">
                    <div class="card-header pb-0 d-flex align-items-center">
                        <h5>{{ headerTitle }}</h5>
                    </div>
                    <PacienteInfo
                        v-model="state.paciente"
                        @setInstitucion="setInstitucion"
                        :isReadOnly="state.id ? true : false"
                    ></PacienteInfo>
                    <DoctorInfo
                        v-model="state.doctor"
                        :isReadOnly="state.id ? true : false"
                    ></DoctorInfo>
                    <div class="card-body theme-form">
                        <div class="row">
                            <div class="col">
                                <div class="mb-3 row">
                                    <label class="col-md-3 col-form-label">Institucion</label>
                                    <div class="col-md-9">
                                        <InstitucionAutocomplete
                                            :class="{ 'is-invalid': v.institucion?.$error }"
                                            v-model="state.institucion"
                                            :disabled="disabled"
                                        />
                                        <div
                                            class="invalid-feedback"
                                            v-if="v.institucion?.$errors.length"
                                        >
                                            {{ v.institucion.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-md-3 col-form-label">Examen</label>
                                    <div
                                        class="col-md-9"
                                        :class="{ 'is-invalid': v.examen.$errors.length }"
                                    >
                                        <Autocomplete
                                            :debounce="1200"
                                            @input="getExamenes"
                                            :items="examenes"
                                            :displayItem="
                                                (item) =>
                                                    typeof item === 'string' ? item : item.name
                                            "
                                            placeholder="Buscar un examen"
                                            @onSelect="onSelectExamen"
                                            ref="examenAutocomplete"
                                            :disabled="disabled"
                                        ></Autocomplete>
                                    </div>
                                    <div class="invalid-feedback" v-if="v.examen.$errors.length">
                                        {{ v.examen.$errors[0].$message }}
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-md-3 col-form-label">Fecha</label>
                                    <div class="col-md-9">
                                        <DateInput v-model="state.date" />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-md-3 col-form-label">Estado</label>
                                    <div class="col-md-9">
                                        <SelectInputSimpleState v-model="state.status" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12" v-if="isReady && state.id">
                <ResultadoFiles
                    v-model="state.files"
                    :idResultado="state.id"
                    v-if="examenSelected"
                    @onUpdate="handleOrderFiles"
                    @onRemove="handleRemoveFile"
                    :readOnly="state.documentStatus > 1"
                />
            </div>
            <div class="col-sm-12" v-if="isReady && state.documentStatus > 1">
                <RenderedFiles
                    :files="state.files.filter((i) => i.selected === true)"
                    :disabled="disabled"
                    @onRemoveRender="handleRemoveRender"
                />
            </div>
            <div class="col-sm-12" v-if="state.id && state.documentStatus === '1' && isReady">
                <DocumentData
                    :key="documentDataKey"
                    v-model="state.documentData"
                    :template="examenTemplate"
                    :files="state.files"
                    :copys="state.internalRenders"
                    :disabled="disabled"
                    :isUpdate="isUpdate"
                    @onSelectTemplate="handleSelectTemplate"
                    @onRenderDocument="(data) => handleAction('generate', data)"
                    @onRemoveRender="handleRemoveRender"
                    @onCreateCopy="handleCreateCopy"
                    @onSave="handleAction('save')"
                    @update:fileList="handleOrderFiles"
                    v-if="examenSelected"
                />
            </div>
            <div class="col-sm-12" v-if="state.id && state.documentStatus === '1' && isReady">
                <MergeFiles
                    :files="state.files"
                    :disabled="disabled"
                    @update:fileList="handleOrderFiles"
                    @onMergeFiles="(data) => handleAction('merge_files', data)"
                />
            </div>
            <Observaciones
                :observaciones="state.observaciones"
                @onAddObservacion="handleAddNewObservacion"
                @onDeleteObservacion="handledeleteNewObservacion"
            />
            <div
                class="col-sm-12"
                v-if="state.id && state.status == 1 && state.documentStatus === '3'"
            >
                <ResendEmail @onSendEmail="handleResend" />
            </div>

            <div class="col">
                <div class="darmin card header-card">
                    <div class="row">
                        <div class="col-12 text-end">
                            <div
                                class="btn btn-warning ms-3 mb-1"
                                @click="handleAction('goback')"
                                v-if="state.id && state.status == 1 && state.documentStatus === '2'"
                            >
                                <i class="far fa-edit"></i>
                                Editar
                            </div>
                            <div
                                class="btn btn-success ms-3 mb-1"
                                @click="vfm.open(finishModalId)"
                                v-if="state.id && state.status == 1 && state.documentStatus === '2'"
                            >
                                <i class="far fa-paper-plane"></i>
                                Publicar
                            </div>
                            <div
                                class="btn btn-primary ms-3 mb-1"
                                @click="handleCreateCopy"
                                v-if="state.id && state.status == 1 && state.documentStatus === '3'"
                            >
                                <i class="far fa-copy"></i>
                                Generar Copia
                            </div>
                            <div
                                class="btn btn-success ms-3 mb-1"
                                @click="handleAction('resend')"
                                v-if="state.id && state.status == 1 && state.documentStatus === '3'"
                            >
                                <i class="far fa-file-import"></i>
                                Reenviar
                            </div>
                            <a
                                class="btn btn-warning ms-3 mb-1"
                                target="_blank"
                                v-if="state.id && state.status == 1 && state.documentStatus === '3'"
                                :href="downloadUrl"
                            >
                                <i class="far fa-file-import"></i>
                                Descargar
                            </a>
                            <div
                                class="btn btn-info ms-3 mb-1"
                                @click="handleAction('merge_close')"
                                v-if="
                                    state.id &&
                                    state.status == 1 &&
                                    state.documentStatus === '1' &&
                                    isUpdate
                                "
                            >
                                <i class="far fa-file-import"></i>
                                Combinar & FInalizar
                            </div>
                            <div
                                class="btn btn-success ms-3 mb-1"
                                @click="handleFinishDocument"
                                v-if="
                                    state.id &&
                                    state.status == 1 &&
                                    state.documentStatus === '1' &&
                                    isUpdate
                                "
                            >
                                <i class="far fa-file-import"></i>
                                Finalizar
                            </div>
                            <div
                                class="btn btn-primary ms-3 mb-1"
                                @click="handleAction('save')"
                                v-if="state.id"
                            >
                                <i class="far fa-save"></i>
                                Guardar
                            </div>
                            <div
                                v-if="!state.id"
                                class="btn btn-primary ms-3 mb-1"
                                @click="handleAction('new')"
                            >
                                <i class="far fa-save"></i>
                                Crear
                            </div>
                            <div
                                v-if="!state.id"
                                class="btn btn-primary ms-3 mb-1"
                                @click="handleAction('new_view')"
                            >
                                <i class="far fa-save"></i>
                                Crear y mostrar
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FinalConfirmModal
            :modalId="finishModalId"
            :files="state.files.filter((i) => i.selected === true)"
            @onConfirm="handleAction('publish')"
        />
        <ResumenConfirmModal
            :modalId="resumenModalId"
            :files="state.files.filter((i) => i.selected === true)"
            @onConfirm="handleAction('close')"
        />
        <pre v-if="env === 'development'">{{ JSON.stringify(state, null, 4) }}</pre>
        <pre v-if="env === 'development'">{{ v }}</pre>
    </WindowLayout>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { defineComponent, reactive, ref, computed, onBeforeMount, onMounted, watch } from 'vue';
import { useVfm } from 'vue-final-modal';
import WindowLayout from '@/layouts/Window.layout.vue';
import Autocomplete from '@/components/form/Autocomplete.vue';
import DateInput from '@/components/form/DateInput.vue';
import SelectInputSimpleState from '@/components/form/SelectInputSimpleState.vue';
import { useSnackbar } from 'vue3-snackbar';
import { useStore } from 'vuex';

import InstitucionAutocomplete from '@/modules/Care/Instituciones/components/InstitucionAutocomplete.vue';

import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import useCompare from '@/services/useCompare';
import useResultado from './services/useResultado';
import useResultadoFile from './services/useResultadoFile';
import useExamen from '../Examenes/services/useExamen';
import useExamenTemplate from '../ExamenTemplate/services/useExamenTemplate';
import useDoctor from '../Doctores/services/useDoctor';

import DocumentData from './components/documentData.vue';
import ResultadoFiles from './components/ResultadoFiles.vue';
import PacienteInfo from '../Pacientes/components/PacienteInfo.vue';
import DoctorInfo from '../Doctores/components/DoctorInfo.vue';
import ResendEmail from './components/resendEmail.vue';

import RenderedFiles from './components/RenderedFiles.vue';
import MergeFiles from './components/MergeFiles.vue';
import Observaciones from '../Components/Observaciones.vue';
import FinalConfirmModal from './components/FinalConfirmModal.vue';
import ResumenConfirmModal from './components/ResumenConfirmModal.vue';

export default defineComponent({
    name: 'ResultadoForm',
    components: {
        WindowLayout,
        Autocomplete,
        PacienteInfo,
        DateInput,
        SelectInputSimpleState,
        DocumentData,
        ResultadoFiles,
        ResendEmail,
        RenderedFiles,
        MergeFiles,
        DoctorInfo,
        Observaciones,
        InstitucionAutocomplete,
        FinalConfirmModal,
        ResumenConfirmModal,
    },
    props: {
        title: { type: String, default: 'Resultado de Examen' },
        headerTitle: { type: String, default: 'Crear Resultado' },
        urlRead: { type: String, required: false },
        urlCreate: { type: String, required: false },
        urlUpdate: { type: String, required: false },
        item: Object,
    },
    setup(props) {
        const store = useStore();
        const isReady = ref(false);
        const snackbar = useSnackbar();
        const finishModalId = Symbol('finishModalId');
        const resumenModalId = Symbol('resumenModalId');
        const vfm = useVfm();
        const {
            resultado,
            createResultado,
            getResultado,
            updateResultado,
            generateDocument,
            mergeFiles,
            closeResultado,
            publishDocument,
            resendDocument,
            gobackDocument,
            removeRender,
            createCopy,
        } = useResultado();
        const { examenes, getExamenes } = useExamen();
        const { getFiles, orderFiles, removeFile } = useResultadoFile();
        const { shallowEqual } = useCompare();

        const { getExamenTemplateByExamen } = useExamenTemplate();
        const { doctor, getDoctor } = useDoctor();

        const state = reactive({
            id: '',
            consecutive: 0,
            paciente: '',
            examen: '',
            template: '',
            doctor: '',
            institucion: '',
            // tratante: '',
            date: '',
            status: 1,
            mode: '2',
            documentData: {},
            documentStatus: '',
            files: [],
            key: '',
            renders: [],
            internalRenders: '',
            observaciones: [],
        });

        const examenTemplate = ref({});

        const documentDataKey = ref(Date.now());

        const disabled = computed(() => state.documentStatus > 1);

        const handleSelectTemplate = (template) => {
            examenTemplate.value = template;
            state.template = template.id;
        };

        /** ***** Validator ***** */
        const rules = computed(() => ({
            paciente: { required },
            examen: { required },
            doctor: { required },
            institucion: { required },
            date: { required },
        }));
        const $v = useVuelidate(rules, state);

        /** ***** Autocompleters ***** */

        const examenAutocomplete = ref(); // autocomplete input
        const examenSelected = ref();
        const onSelectExamen = (examen) => {
            state.examen = examen.id;
            examenAutocomplete.value.setText(examen.name);
            examenSelected.value = examen;
        };

        /** Helpers */
        const downloadUrl = computed(
            // eslint-disable-next-line comma-dangle
            () => `${process.env.VUE_APP_DARMIN_RESULTADOS_URL}/documento/examen/${state.key}`
        );

        /** ***** Files Callbacks ***** */
        const handleOrderFiles = async () => {
            // eslint-disable-next-line no-underscore-dangle
            const files = state.files.map((f) => ({ id: f.file._id, selected: f.selected }));
            await orderFiles(state.id, files);
            state.files = await getFiles(state.id);
        };

        const handleUpdateFileList = (files) => {
            console.log('files', files);
        };

        /** ***** Lifecycle ***** */
        const setState = () => {
            state.id = resultado.value.id;
            state.paciente = resultado.value.paciente.id;
            state.examen = resultado.value.examen.id;
            state.template = resultado.value.template?.id;
            state.doctor = resultado.value.doctor.id;
            state.institucion = resultado.value.institucion?.id;
            // state.tratante = resultado.value.tratante.id;
            state.date = resultado.value.date;
            state.status = resultado.value.status;
            state.mode = resultado.value.mode.toString();
            state.documentStatus = resultado.value.documentStatus.toString();
            state.consecutive = resultado.value.consecutive;
            state.documentData = { ...resultado.value.documentData } || {};
            state.files = resultado.value.files ? resultado.value.files : [];
            state.renders = resultado.value.renders ? resultado.value.renders : [];
            state.internalRenders = resultado.value.internalRenders
                ? resultado.value.internalRenders
                : [];
            state.key = resultado.value.key;
            state.observaciones = resultado.value.observaciones;

            examenSelected.value = resultado.value.examen;
            examenAutocomplete.value.setText(resultado.value.examen?.name || '');
            examenTemplate.value = resultado.value.template;
            // eslint-disable-next-line no-self-assign
            resultado.value.status = resultado.value.status;
            documentDataKey.value = Date.now();
        };

        const resetWindows = () => {
            state.id = '';
            state.consecutive = 0;
            state.paciente = '';
            state.examen = '';
            state.template = '';
            state.doctor = '';
            state.institucion = '';
            // state.tratante = '';
            state.date = '';
            state.status = 1;
            state.mode = '2';
            state.documentData = {};
            state.documentStatus = '';
            state.files = [];
            state.key = '';
            state.renders = [];
            state.internalRenders = '';
            state.observaciones = [];

            examenSelected.value = null;
            examenAutocomplete.value.setText('');
            examenTemplate.value = {};
            // eslint-disable-next-line no-self-assign
            resultado.value = null;
            documentDataKey.value = Date.now();
            $v.value.$reset();
        };

        onBeforeMount(async () => {});

        /** ***** Actions ***** */
        const handleAction = async (action, data = {}) => {
            await $v.value.$validate();
            if (!$v.value.$error) {
                // No errors
                let text;
                switch (action) {
                    case 'new':
                    case 'new_view':
                        await createResultado({
                            paciente: state.paciente,
                            doctor: state.doctor,
                            institucion: state.institucion,
                            // tratante: state.tratante,
                            examen: state.examen,
                            date: state.date,
                            mode: state.mode,
                            status: state.status,
                            documentData: state.documentData,
                            observaciones: state.observaciones,
                        });
                        if (action === 'new_view') {
                            store.dispatch('ContentManager/openWindow', {
                                id: `ResultadoForm_${resultado.value.id}`,
                                component: 'ResultadoForm',
                                name: 'Resultado',
                                params: {
                                    title: 'Resultado',
                                    headerTitle: 'Editar Resultado',
                                    item: { ...resultado.value },
                                },
                            });
                        }
                        resetWindows();
                        text = 'Resultado creado';
                        break;
                    case 'save_sh':
                        await updateResultado(state.id, {
                            paciente: state.paciente,
                            doctor: state.doctor,
                            institucion: state.institucion,
                            // tratante: state.tratante,
                            examen: state.examen,
                            template: state.template,
                            date: state.date,
                            status: state.status,
                            documentData: state.documentData,
                            observaciones: state.observaciones,
                        });
                        break;
                    case 'save':
                        await updateResultado(state.id, {
                            paciente: state.paciente,
                            doctor: state.doctor,
                            institucion: state.institucion,
                            // tratante: state.tratante,
                            examen: state.examen,
                            template: state.template,
                            date: state.date,
                            status: state.status,
                            documentData: state.documentData,
                            observaciones: state.observaciones,
                        });
                        text = 'Resultado actualizado';
                        break;
                    case 'generate':
                        await updateResultado(state.id, {
                            paciente: state.paciente,
                            doctor: state.doctor,
                            institucion: state.institucion,
                            // tratante: state.tratante,
                            examen: state.examen,
                            template: state.template,
                            date: state.date,
                            status: state.status,
                            documentData: state.documentData,
                            observaciones: state.observaciones,
                        });
                        await generateDocument(state.id, data.templateId, data.files);
                        text = 'Documento generado';
                        break;
                    case 'merge_files':
                        await updateResultado(state.id, {
                            paciente: state.paciente,
                            doctor: state.doctor,
                            institucion: state.institucion,
                            // tratante: state.tratante,
                            examen: state.examen,
                            template: state.template,
                            date: state.date,
                            status: state.status,
                            documentData: state.documentData,
                            observaciones: state.observaciones,
                        });
                        await mergeFiles(state.id, data.files);
                        text = 'Documento generado';
                        break;
                    case 'close':
                        await closeResultado(state.id);
                        text = 'Documento finalizado';
                        break;
                    case 'goback':
                        await gobackDocument(state.id);
                        text = 'Documento generado';
                        break;
                    case 'publish':
                        await publishDocument(state.id);
                        text = 'Documento publicado';
                        break;
                    case 'resend':
                        await resendDocument(state.id);
                        text = 'Documento enviado';
                        break;
                    case 'merge_close':
                        await updateResultado(state.id, {
                            paciente: state.paciente,
                            doctor: state.doctor,
                            institucion: state.institucion,
                            // tratante: state.tratante,
                            examen: state.examen,
                            template: state.template,
                            date: state.date,
                            status: state.status,
                            documentData: state.documentData,
                            observaciones: state.observaciones,
                        });
                        await mergeFiles(
                            state.id,
                            state.files.map((f) => f.file.id)
                        );
                        setState();
                        state.files[state.files.length - 1].selected = true;
                        await orderFiles(
                            state.id,
                            // eslint-disable-next-line no-underscore-dangle
                            state.files.map((f) => ({ id: f.file._id, selected: f.selected }))
                        );
                        await closeResultado(state.id);
                        setState();
                        text = 'Documento finalizado';
                        break;
                    default:
                        break;
                }
                if (text) {
                    snackbar.add({
                        type: 'success',
                        text,
                    });
                    setState();
                }
            } else {
                console.log($v.value.$errors);
                snackbar.add({
                    type: 'error',
                    text: 'Ups no podemos enviar la informacion sin algunos datos',
                });
            }
        };

        const setInstitucion = (value) => {
            if (!state.documentStatus || state.documentStatus === '1') {
                if (state.institucion !== value.id) state.institucion = value.id;
            }
        };

        const handleAddNewObservacion = (data) => {
            state.observaciones.push(data.observacion);
            if (state.id) handleAction('save_sh');
        };

        const handledeleteNewObservacion = (data) => {
            // console.log('data.index', data.index);
            state.observaciones = state.observaciones.filter((o, i) => {
                console.log('i', i);
                return i !== data.index;
            });
            if (state.id) handleAction('save_sh');
        };

        onMounted(async () => {
            if (props.item) {
                await Promise.all([getResultado(props.item.id)]);

                if (resultado.value) {
                    setState();

                    // eslint-disable-next-line no-unused-vars
                    const [templates, d] = await Promise.all([
                        getExamenTemplateByExamen(state.examen),
                        getDoctor(state.doctor),
                    ]);
                    if (!state.template) {
                        const tm = templates.find(
                            (template) =>
                                // eslint-disable-next-line implicit-arrow-linebreak, comma-dangle, operator-linebreak
                                doctor.value.templates.find((t) => t.id === template.id) !==
                                // eslint-disable-next-line comma-dangle
                                undefined
                            // eslint-disable-next-line function-paren-newline
                        );
                        if (tm) {
                            handleSelectTemplate(tm);
                            if (state.template) {
                                handleAction('save_sh');
                            }
                        }
                    }
                }
            }
            isReady.value = true;
        });

        const handleFinishDocument = () => {
            const c = state.files.filter((f) => f.active);
            console.log('c', c);
            if (c.length === 0 || c.length > 1) {
                vfm.open(resumenModalId);
            }
            // handleAction('close');
        };

        const handleResend = (email) => {
            resendDocument(state.id, email);
        };

        const handleRemoveFile = async (fileId) => {
            await removeFile(state.id, fileId);
            state.files = state.files.filter((i) => i.file.id !== fileId);
        };

        const handleRemoveRender = async (renderId) => {
            await removeRender(state.id, renderId);
            setState();
        };

        const handleCreateCopy = async () => {
            await createCopy(state.id);
            setState();
        };

        // eslint-disable-next-line arrow-body-style
        const isUpdate = computed(() => {
            if (resultado.value.documentData) {
                return (
                    shallowEqual(
                        {
                            paciente: resultado.value.paciente.id,
                            doctor: resultado.value.doctor.id,
                            examen: resultado.value.examen.id,
                            date: resultado.value.date,
                            status: resultado.value.status,
                        },
                        {
                            paciente: state.paciente,
                            doctor: state.doctor,
                            examen: state.examen,
                            date: state.date,
                            status: Number(state.status),
                            // eslint-disable-next-line comma-dangle
                        }
                    ) && shallowEqual(resultado.value.documentData, state.documentData)
                );
            }
            return (
                shallowEqual(
                    {
                        paciente: resultado.value.paciente.id,
                        doctor: resultado.value.doctor.id,
                        examen: resultado.value.examen.id,
                        date: resultado.value.date,
                        status: resultado.value.status,
                    },
                    {
                        paciente: state.paciente,
                        doctor: state.doctor,
                        examen: state.examen,
                        date: state.date,
                        status: Number(state.status),
                        // eslint-disable-next-line comma-dangle
                    }
                ) && shallowEqual({}, state.documentData)
            );
        });

        let tOut;

        watch(
            () => ({ ...state.documentData }),
            () => {
                if (!tOut) {
                    tOut = setTimeout(() => {
                        handleAction('save_sh');
                        tOut = undefined;
                    }, 3000);
                }
                // eslint-disable-next-line comma-dangle
            }
        );

        return {
            isReady,
            isUpdate,
            state,
            disabled,
            examenTemplate,
            handleSelectTemplate,
            handleRemoveRender,
            handleCreateCopy,
            handleResend,
            resultado,
            documentDataKey,
            downloadUrl,
            /** Autocompleters */
            examenes,
            getExamenes,
            examenAutocomplete,
            onSelectExamen,
            examenSelected,
            /** Actions */
            handleRemoveFile,
            handleAction,
            handleOrderFiles,
            handleUpdateFileList,
            env: process.env.NODE_ENV,
            v: $v,
            handleAddNewObservacion,
            handledeleteNewObservacion,
            setInstitucion,
            finishModalId,
            vfm,
            handleFinishDocument,
            resumenModalId,
        };
    },
});
</script>

<style scoped lang="scss">
Datepicker {
    --dp__font_family: 'Roboto, sans-serif';
}
</style>

<template>
    <div class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th width="8%">Id</th>
                    <th>Fecha</th>
                    <th>Especialista</th>
                    <th width="10%" class="text-center">...</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="c in consultas" :key="c.id">
                    <td>{{ c.consecutive }}</td>
                    <td>{{ formatDate(c.date) }}</td>
                    <td>
                        {{ c.doctor.map((d) => d.fullName).join(', ') }}
                    </td>
                    <td class="d-flex justify-content-end">
                        <i class="fa fa-lg fa-eye" role="button" @click="handleOpenConsulta(c)"></i>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { DateTime } from 'luxon';

import useConsulta from '../services/useConsulta';

export default {
    name: 'ConsultaList',
    props: {
        pacienteId: String,
    },
    setup(props) {
        const store = useStore();
        const consultaServices = useConsulta();
        onMounted(() => {
            consultaServices.queryConsultasByPaciente(props.pacienteId, 999);
        });

        const handleOpenConsulta = (c) => {
            store.dispatch('ContentManager/openWindow', {
                id: `ConsultaOftalmologiaForm_${c.id}`,
                component: 'ConsultaOftalmologiaForm',
                name: `Consulta ${c.consecutive}`,
                params: {
                    title: 'Consulta',
                    headerTitle: `Ver Consulta ${c.consecutive}`,
                    item: { ...c },
                },
            });
        };

        const handleOpenConsultaObservacion = (c) => {
            store.dispatch('ContentManager/openWindow', {
                id: `ConsultaOftalmologiaForm_${c.id}`,
                component: 'ConsultaOftalmologiaForm',
                name: `Consulta ${c.consecutive}`,
                params: {
                    title: 'Consulta',
                    headerTitle: `Ver Consulta ${c.consecutive}`,
                    item: { ...c },
                    action: 'observacion',
                },
            });
        };

        const formatDate = (date) => DateTime.fromISO(date).toFormat('yyyy-MM-dd');
        return {
            consultas: consultaServices.consultas,
            count: consultaServices.count,
            handleOpenConsulta,
            handleOpenConsultaObservacion,
            formatDate,
        };
    },
};
</script>
<style scoped>
tbody tr:hover {
    background-color: var(--background-hover);
}
</style>

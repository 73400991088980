<template>
    <div class="card-body theme-form" v-if="paciente">
        <div class="row mb-3">
            <div class="col">
                <p class="mb-0">Paciente:</p>
                <p class="mb-0 fs-4 fw-bolder" @click="handleOpen">
                    <span role="button" @click="handleOpen">{{ paciente.fullName }}</span>
                    <i
                        role="button"
                        class="ms-2 edit-link fa fa-edit"
                        v-if="!isReadOnly"
                        @click="handleEdit"
                    ></i>
                </p>
                <div class="d-flex flex-wrap">
                    <p class="pe-3 mb-0" v-if="paciente.eps?.name">
                        <i class="fa fa-credit-card"></i> Entidad
                        {{ paciente.eps?.name }}
                    </p>
                    <p class="pe-3 mb-0">
                        <i class="fa fa-credit-card"></i> {{ paciente.identification_type }}.
                        {{ paciente.identification }}
                    </p>
                    <p class="pe-3 mb-0">
                        <i class="fa fa-calendar"></i>
                        {{ getNacimientoFormated(paciente.born) }} ({{ getEdad(paciente.born) }})
                    </p>
                    <p class="pe-3 mb-0"><i class="fa fa-phone"></i> {{ paciente.contact[0] }}</p>
                    <p class="pe-3 mb-0">
                        <i class="fa fa-home"></i> {{ paciente.address || '-' }}
                    </p>
                    <p class="pe-3 mb-0">
                        <i class="fa fa-mailbox"></i> {{ paciente.email || '-' }}
                    </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div
                    role="button"
                    class="d-flex align-items-center justify-content-between collapsed border-bottom mb-2"
                    data-bs-toggle="collapse"
                    :data-bs-target="`#collapse-${ramId}`"
                    :aria-expanded="true"
                    :aria-controls="`collapse-${ramId}`"
                >
                    <p class="mb-0 fs-6 fw-bolder">Historia</p>
                    <i class="fas fa-angle-up"></i>
                </div>
                <div class="collapse" :id="`collapse-${ramId}`">
                    <ConsultaList :pacienteId="modelValue" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div
                    role="button"
                    class="d-flex align-items-center justify-content-between collapsed border-bottom mb-2"
                    data-bs-toggle="collapse"
                    :data-bs-target="`#collapse-${ramId2}`"
                    :aria-expanded="true"
                    :aria-controls="`collapse-${ramId2}`"
                >
                    <p class="mb-0 fs-6 fw-bolder">Antecedentes</p>
                    <i class="fas fa-angle-up"></i>
                </div>
                <div class="collapse" :id="`collapse-${ramId2}`">
                    <div class="row">
                        <div class="col-12 mb-3">
                            <label class="col-form-label">Antecedentes Personales</label>
                            <textarea
                                class="form-control"
                                rows="4"
                                v-model="paciente.antecedentes_personales"
                            ></textarea>
                        </div>
                        <div class="col-12 mb-3">
                            <label class="col-form-label">Antecedentes familiares</label>
                            <textarea
                                class="form-control"
                                rows="4"
                                v-model="paciente.antecedentes_familiares"
                            ></textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-end">
                            <button
                                class="btn btn-sm btn-outline-primary"
                                @click="handleUpdateAntecedentes"
                            >
                                Actualizar
                            </button>
                        </div>
                    </div>
                    <pre v-if="env === 'development'">{{ paciente }}</pre>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body theme-form" v-else>
        <div class="row">
            <div class="col">
                <div class="row" :class="{ 'is-invalid': v.paciente.$errors.length }">
                    <label class="col-md-3 col-form-label">Paciente</label>
                    <div class="col-md-9" :class="{ 'is-invalid': v.paciente.$errors.length }">
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <Autocomplete
                                    :class="{ 'is-invalid': v.paciente.$errors.length }"
                                    :debounce="1200"
                                    @input="getPacientes"
                                    :items="pacientes"
                                    :displayItem="
                                        (item) =>
                                            typeof item === 'string' ? item : item.selectName
                                    "
                                    placeholder="Buscar un paciente"
                                    @onSelect="onSelectPaciente"
                                    ref="pacienteAutocomplete"
                                ></Autocomplete>
                            </div>
                            <button
                                class="btn btn-primary btn-sm ms-3"
                                @click="handleOpenPacienteModal"
                            >
                                <i class="far fa-lg fa-user-plus"></i>
                            </button>
                        </div>
                    </div>
                    <div class="invalid-feedback" v-if="v.paciente.$errors.length">
                        {{ v.paciente.$errors[0].$message }}
                    </div>
                </div>
            </div>
        </div>
        <PacienteModalForm
            :modal-id="pacienteModalId"
            @onClose="handleClosePacienteModal"
            @onConfirm="handleConfirmarPacienteModal"
        />
    </div>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import { computed, ref, onBeforeMount, watch } from 'vue';
import { useStore } from 'vuex';
import Autocomplete from '@/components/form/Autocomplete.vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import { useVfm } from 'vue-final-modal';

import PacienteModalForm from '@/modules/Care/Pacientes/PacienteModalForm.vue';
import usePaciente from '@/modules/Care/Pacientes/services/usePaciente';
import ConsultaList from '@/modules/Care/Consulta/components/ConsultaList.vue';

export default {
    name: 'PacienteInfo',
    components: { Autocomplete, ConsultaList, PacienteModalForm },
    props: {
        modelValue: String,
        isReadOnly: Boolean,
    },
    setup(props, { emit }) {
        const store = useStore();
        const pacienteModalId = Symbol('PacienteModalForm');
        const ramId = ref(Math.floor(Math.random() * 1000));
        const ramId2 = ref(Math.floor(Math.random() * 1000));

        const vfm = useVfm();

        // eslint-disable-next-line object-curly-newline, operator-linebreak
        const {
            pacientes,
            getPacientes,
            getPaciente,
            paciente,
            getNacimientoFormated,
            getEdad,
            updatePacienteAntecedentes,
        } = usePaciente();

        const handleOpenPacienteModal = () => {
            vfm.open(pacienteModalId);
        };
        const handleClosePacienteModal = () => {
            vfm.close(pacienteModalId);
        };

        /** ***** Autocompleters ***** */
        const pacienteAutocomplete = ref(); // autocomplete input
        const onSelectPaciente = (selected) => {
            emit('update:modelValue', selected.id);
            emit('setInstitucion', selected.eps);
            pacienteAutocomplete.value.setText(selected.selectName);
            paciente.value = selected;
            pacientes.value = [];
        };

        const handleConfirmarPacienteModal = (data) => {
            emit('update:modelValue', data.id);
            pacienteAutocomplete.value.setText(data.selectName);
            paciente.value = data;
            pacientes.value = [];
            handleClosePacienteModal();
        };

        /** ***** Validator ***** */
        const rules = computed(() => ({
            paciente: { required },
        }));

        const state = computed(() => ({ paciente: props.modelValue }));

        const v = useVuelidate(rules, state);

        const handleGetPaciente = async (value) => {
            await getPaciente(value);
            emit('setInstitucion', paciente.value.eps);
        };

        onBeforeMount(() => {
            if (props.modelValue) {
                handleGetPaciente(props.modelValue);
            }
        });

        watch(
            () => props.modelValue,
            (newVal) => {
                if (newVal) {
                    if (paciente.value) {
                        if (newVal && newVal !== paciente.value.id) {
                            handleGetPaciente(props.modelValue);
                        }
                    } else if (newVal) {
                        handleGetPaciente(props.modelValue);
                    }
                } else {
                    paciente.value = null;
                }
                // eslint-disable-next-line comma-dangle
            }
        );

        const handleEdit = () => {
            emit('update:modelValue', '');
            paciente.value = null;
        };

        const handleOpen = () => {
            store
                .dispatch('ContentManager/openWindow', {
                    id: `PacienteForm_${paciente.value.id}`,
                    component: 'PacienteForm',
                    name: `Paciente ${paciente.value.consecutive}`,
                    params: {
                        title: 'Paciente',
                        headerTitle: `Paciente ${paciente.value.consecutive}`,
                        item: { ...paciente.value },
                    },
                })
                .catch((error) => {
                    console.log(error);
                });
        };

        const handleUpdateAntecedentes = () => {
            // eslint-disable-next-line camelcase
            const { antecedentes_familiares, antecedentes_personales } = paciente.value;
            updatePacienteAntecedentes(paciente.value.id, {
                antecedentes_familiares,
                antecedentes_personales,
            });
        };

        return {
            pacientes,
            getPacientes,
            onSelectPaciente,
            pacienteAutocomplete,
            paciente,
            getNacimientoFormated,
            getEdad,
            ramId,
            ramId2,
            handleEdit,
            v,
            handleUpdateAntecedentes,
            env: process.env.NODE_ENV,
            handleOpenPacienteModal,
            handleClosePacienteModal,
            handleConfirmarPacienteModal,
            pacienteModalId,
            handleOpen,
        };
    },
};
</script>
<style scoped>
.edit-link {
    font-size: 0.5em;
}

.collapsed i {
    transform: rotate(180deg);
}
</style>

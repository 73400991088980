<template>
    <div class="darmin card">
        <div
            role="button"
            class="card-header pb-0 d-flex align-items-center justify-content-between"
            data-bs-toggle="collapse"
            :data-bs-target="`#card-${ramId}`"
            :aria-expanded="!disabled"
            :aria-controls="`card-${ramId}`"
        >
            <h5>Documentos Generados</h5>
            <i class="fas fa-angle-up"></i>
        </div>
        <div class="card-body theme-form">
            <div class="collapse show" :id="`card-${ramId}`">
                <div class="card-body files">
                    <div class="row g-0" v-if="!files?.length">No se han generado documentos.</div>
                    <div class="row g-0" v-else>
                        <div class="col-lg-6 col-xl-4 mb-1 p-1" v-for="(file, i) in files" :key="i">
                            <RenderedFile
                                :file="file.file"
                                :disabled="disabled"
                                @onRemoveRender="(id) => $emit('onRemoveRender', id)"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { defineComponent, ref } from 'vue';
import RenderedFile from './RenderedFile.vue';

export default defineComponent({
    name: 'RenderedFiles',
    components: { RenderedFile },
    props: {
        files: Array,
        disabled: Boolean,
    },
    setup() {
        const ramId = ref(Math.floor(Math.random() * 1000));

        return {
            ramId,
        };
    },
});
</script>

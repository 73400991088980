<template>
    <Autocomplete
        :debounce="1200"
        @input="getInstituciones"
        :items="instituciones"
        :displayItem="(item) => (typeof item === 'string' ? item : item.name)"
        placeholder="Buscar una Entidad"
        @onSelect="onSelectEps"
        ref="epsAutocomplete"
        :disabled="disabled"
    ></Autocomplete>
</template>
<script>
import { ref, onBeforeMount, watch } from 'vue';
import Autocomplete from '@/components/form/Autocomplete.vue';
import useInstituciones from '../services/useInstituciones';

export default {
    name: 'EntidadAutocomplete',
    components: { Autocomplete },
    props: {
        modelValue: String,
        isReadOnly: Boolean,
        disabled: { type: Boolean, default: false },
    },
    setup(props, { emit }) {
        // eslint-disable-next-line object-curly-newline
        const { institucion, getInstitucion, instituciones, getInstituciones } = useInstituciones();

        const epsAutocomplete = ref(); // autocomplete input
        const epsSelected = ref(null);
        const onSelectEps = (eps) => {
            emit('update:modelValue', eps.id);
            epsAutocomplete.value.setText(eps.name);
            epsSelected.value = eps;
        };

        const handleGetInstitucion = async (value) => {
            await getInstitucion(value);
            epsAutocomplete.value.setText(institucion.value.name);
            epsSelected.value = institucion;
        };

        onBeforeMount(() => {
            if (props.modelValue) {
                handleGetInstitucion(props.modelValue);
            }
        });

        watch(
            () => props.modelValue,
            (newVal) => {
                if (newVal) {
                    if (institucion.value) {
                        if (newVal && newVal !== institucion.value.id) {
                            handleGetInstitucion(props.modelValue);
                        }
                    } else if (newVal) {
                        handleGetInstitucion(props.modelValue);
                    }
                } else {
                    institucion.value = null;
                    epsAutocomplete.value.setText('');
                }
                // eslint-disable-next-line comma-dangle
            }
        );

        return {
            instituciones,
            getInstituciones,
            epsAutocomplete,
            epsSelected,
            onSelectEps,
        };
    },
};
</script>
<style lang="scss" scoped></style>
